<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="意见反馈" left-arrow @click-left="$router.back()" />
    <van-cell-group>
      <!-- <van-field
        v-model="feedback.title"
        name="问题"
        label="问题"
        placeholder="问题"
        :rules="[{ required: true, message: '问题' }]"
        :error-message="errMes.title"
      /> -->
      <van-field
        v-model="feedback.detailsText"
        rows="4"
        autosize
        name="意见反馈"
        label="意见反馈"
        placeholder="请输入详细描述"
        type="textarea"
        required
        :rules="[{ required: true, message: '请输入详细描述' }]"
        :error-message="errMes.detailsText"
        @blur="checkdetailsText"
      />

      <van-field name="uploader" label="图片附件">
        <template #input>
          <van-uploader
            v-model="fileList"
            multiple
            :max-count="1"
            :after-read="afterReadAudio"
          />
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button
        type="info"
        size="large"
        round
        block
        @click="AddFeedbackHanlder"
        >确认提交</van-button
      >
    </div>
  </div>
</template>

<script>
//import { changePassWord } from "@/api/user";
import { AddFeedBack } from "@/api/feedback";
import { uploadfile } from "@/api/file";
import { Toast } from "vant";

export default {
  name: "user-Feedback",
  data() {
    return {
      feedback: {
        title: "",
        detailsText: "",
        imageUrl: "",
      },
      errMes: {
        title: "",
        detailsText: "",
        newPassWord2: "",
      },
      fileList: [
        // {
        //   url: 'https://img01.yzcdn.cn/vant/tree.jpg',
        //   imageFit: 'contain',
        //   previewSize: 120,
        // },
      ],
    };
  },
  created() {},
  methods: {
    checkdetailsText() {
      if (!this.feedback.detailsText) {
        this.errMes.detailsText = "请输入详细描述";
        return false;
      }
      this.errMes.detailsText = "";
      return true;
    },
    // 保存用户编辑
    async AddFeedbackHanlder() {
      try {
        if (!this.checkdetailsText()) {
          return false;
        }

        const data = await AddFeedBack(this.feedback);
        if (data.data.success) {
          Toast("提交成功,非常感谢您意见反馈");
          this.feedback.title = "";
          this.feedback.detailsText = "";
          this.feedback.imageUrl = "";
          this.$router.back();
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
        Toast("保存失败：" + error);
      }
    },
    afterReadAudio: async function (files) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfile(formData);

      let url = aresult.data; // Get url from response
      this.feedback.imageUrl = url;

      this.fileList = [];
      this.fileList.push({
        url: url,

        imageFit: "contain",
        previewSize: 80,
      });

      Toast("文件上传成功");
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
