import request from '@/utils/request'

export function AddFeedBack (params) {
  return request({
    url: '/api/Feedback/Add',
    data: {...params },
    method: 'post',
    header:{
      'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    },
  })
}